import { useIntl } from 'react-intl'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import React from 'react'
import Badge from '@fnd/components/Badge'
import Wrapper from '@fnd/components/Wrapper'
import { LogoFull } from '@fnd/components/Logo/Logo'

export default () => {
  const intl = useIntl()

  const PianoPlayground = React.lazy(() => import('@fnd/components/Piano/PianoPlayground'))

  return (
    <Wrapper className="maintenance">
      <div className="maintenance-logo">
        <LogoFull />
      </div>

      <SectionTitle
        className="pt-0 mt-0 flex flex-col items-center"
        title={intl.formatMessage({ id: 'maintenance.title' })}
        subtitle={intl.formatMessage({ id: 'maintenance.subtitle' })}
        badge={
          <Badge
            variant="primary-light"
            icon="wrench"
            label={intl.formatMessage({ id: 'maintenance.title' })}
          />}
      >
        <p className="text-center md:text-xl mt-4 max-w-4xl mx-auto">
          {intl.formatMessage({ id: 'maintenance.body' })}
        </p>
      </SectionTitle>

      <React.Suspense fallback={null}>
        <PianoPlayground />
      </React.Suspense>

      <div className="maintenance-footer">
        <p className="text-center opacity-50 text-lg mt-4 mx-auto">
          {intl.formatMessage({ id: 'maintenance.footer' })}
        </p>
      </div>
    </Wrapper >
  )
}
